




























import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Employee from '@/calendesk/models/DTO/Response/Employee'
export default mixins(DraftElement).extend({
  name: 'EmployeeCard',
  props: {
    employee: {
      type: Employee
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
